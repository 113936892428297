// import axios from "axios"
// import config from "../../../config"
import http from '@/services/BackendService'

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
    retreiveSocialLink(){
        return new Promise((resolve, reject) => {
            // axios.get(`${config.apiURL}/learning/socialLink`, {
            http.get('learning/socialLink')
                .then(response => {
                    resolve(response.data.room)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}