<template>
    <div class="container-fluid">
        <div class="row mt-3 justify-content-center">
            <div class="col-10 col-12 col-md-8 col-lg-4 col-xl-4">
              <div class="card mb-3 card-backgroung">
                  <div class="card-header text-white text-center">
                      <b>DAFTAR TERIMA NOTIFIKASI LINE</b>
                  </div>
                  <div class="card-body card-backgroung-green">

                    <!-- <p>status : {{register_status}}</p>
                    <p>line_code  : {{line_code}}</p>
                    <p>link  : {{link}}</p> -->
                    
                    <button class="btn btn-primary btn-lg btn-block"
                      v-if="register_status"
                      @click="update()"
                    >
                      UPDATE LINE NOTIFY
                    </button>

                    <button type="button" 
                      class="btn text-white btn-lg btn-block"
                      :class="register_status ? 'btn-success' : 'btn-primary'"
                      :disabled="register_status"  
                      @click="!register_status && line_code == null ? register() : confirm()"
                      v-if="link == ''"
                    >
                      <!-- <i v-if="register_status == true" class="fa fa-check-circle" aria-hidden="true"></i>  -->
                      
                      <span v-if="register_status == false && line_code == null"> DAFTAR LINE NOTIFY</span>
                      <span v-else-if="register_status == false && line_code != null"> PASTIKAN/CONFIRM</span>
                      <span v-else> CONECTED</span>
                    </button> 

                    <a :href="link" class="btn btn-primary text-white btn-lg btn-block"
                      v-if="link != ''"  
                    >
                      TERUSKAN <i class="fa fa-arrow-circle-right"></i> 
                    </a>

                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import axios from "axios"
    // import config from "../../../src/config"
    // import moment from "../../../node_modules/moment";
    import http from '@/services/BackendService'


    export default {
        mounted(){
            this.queryString()
            this.retureiveRegisterStatus()
        },
        data(){
            return{
                status: false, //registered: true , not register: false
                link: '', //register link from line notify API
                line_code: '',
                state: '',
                register_status: false,
                button_text: '',
                disabled: false
            }
        },
        methods:{
          update(){
            this.register_status = false
            this.line_code = null
          },
          confirm(){
              this.$Progress.start()
              http.get('lineNotify/registerConfirm?code='+this.line_code)
                  .then(response => {
                    if(response.data.response.status == 200){
                      this.register_status = true
                      this.$swal("berhasil", "pendaftaran berhasil", "success");
                      this.line_code = ""
                    }else{
                      this.$swal("gagal", "pendaftaran di tolak", "warning"); 
                      this.register_status = false
                      // this.line_code = ""
                    }
                    this.$Progress.finish()
                  })
                  .catch(error => {
                      if(error.response.status == 401 || error.response.status == 419){
                        this.$store.dispatch("authentication/cencelAuthen")
                      }
                      this.$Progress.fail()
                  })
          },
          queryString(){
              const urlSearchParams = new URLSearchParams(window.location.search);
              const params = Object.fromEntries(urlSearchParams.entries());
              // console.log(params)
              if(params == {}){
                  this.line_code = null
              }else{
                  this.line_code = params.code
                  // this.register_status = false
                  // this.user = params.state
              }
          },
          register(){
              this.$Progress.start()
              http.get(`lineNotify/register`)
                .then(response => {
                    this.link = response.data.link
                    console.log(response.data)   
                    this.$Progress.finish()
                }).catch(error => {
                  if(error.response.status == 401 || error.response.status == 419){
                    this.$store.dispatch("authentication/cencelAuthen")
                  }
                  this.$Progress.fail()
                });
          },
          retureiveRegisterStatus(){
            http.get(`lineNotify/registerStatus`)
              .then(response => {
                if(this.line_code != null){
                  this.register_status = false
                }else{
                  this.register_status = response.data.register_status
                }

              })
              .catch(error => {
                if(error.response.status == 401 || error.response.status == 419){
                  this.$store.dispatch("authentication/cencelAuthen")
                }
              })
          }          
        }
    }
</script>

<style lang="css" scoped>
  .card-backgroung {
    background-color: #646467;
  }

  .card-backgroung-green {
    background-color: white;
  }
</style>