<template>
  <div id="app">
    <header>
      <!-- <div class="container-fluid"> -->
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <router-link to="/">
          <a class="navbar-brand" href="#">KISDA</a>
        </router-link>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <!-- <li class="nav-item" v-if="logedIn">
              <router-link :to="{name: 'score-study'}">
                <a class="nav-link">Hasil</a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/news">
                <a class="nav-link">Istihar</a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/news">
                <a class="nav-link">Idarah</a>
              </router-link>
            </li> -->
          </ul>

          <router-link :to="{name: 'login'}" v-show="!logedIn">
            <button class="btn btn-outline-success my-2 my-sm-0" type="button">
              LOGIN
            </button>
          </router-link>

          <router-link :to="{name: 'logout'}">
            <button v-show="logedIn" class="btn btn-outline-success my-2 my-sm-0" type="submit">
              LOGOUT
            </button>
          </router-link>
        </div>
      </nav>
    </header>

    <router-view></router-view>

    <!-- progressbar -->
    <vue-progress-bar></vue-progress-bar>
    <!-- \.progressbar -->

    <footer class="bg-dark" style="position: fixed;left: 0;bottom: 0;height: 10px;width: 100%;padding-bottom: 2rem!important;padding-top: 1rem!important;">
        <div class="container ">
            <div class="row text-center text-white">
                <div class="col">
                  <router-link to="/" style="color: white !important">
                    <div><i class="fas fa-home"></i> Home</div>
                  </router-link>
                </div>
                <div class="col">
                  <router-link :to="{name: 'profile'}" style="color: white !important">
                    <i class="fas fa-user-circle"></i> profil
                  </router-link>
                </div>
                <div class="col">
                    <router-link :to="{name: 'news'}" style="color: white !important">
                      <i class="fas fa-history"></i> berita
                    </router-link>
                </div>
              </div>
        </div>
    </footer>
  </div>
</template>

<script>
  let brand = " | " + process.env.VUE_APP_TITLE
  export default {
    computed: {
      logedIn() {
        // return localStorage.getItem('smk_access_token')
        return this.$store.getters['authentication/logedIn']
      },
    },
    name: 'App',
    watch: {
        '$route':{
          handler: (to) => {
            document.title = to.meta.title + brand
          },
          immediate: true
        }
      },
  }
</script>

<style>
  .footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 10px;
    width: 100%;
    padding-bottom: 2rem!important;
    padding-top: 1rem!important;
  }
</style>

