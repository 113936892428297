import Vue from 'vue'
import moment from '../../node_modules/moment'

Vue.filter('upText', function(text){
    return text.charAt(0).toUpperCase() + text.slice(1)
});

Vue.filter('rumiTime', function(time){
    return moment(time, "HH:mm").format("HH:mm");
    // return moment(created).format('HH:mm');
});

Vue.filter('dayOfWeek', function(dayId){
    var dayName = ''
    if(dayId == 1){
        dayName = 'Isnin'
    }else if(dayId == 2){
        dayName = 'Selasa'
    }else if(dayId == 3){
        dayName = 'Rabu'
    }else if(dayId == 4){
        dayName = 'Khamis'
    }else if(dayId == 5){
        dayName = 'Jumat'
    }else if(dayId == 6){
        dayName = 'Sabtu'
    }else if(dayId == 0){
        dayName = 'Ahad'
    }
    return dayName;
  });

export default {
    upText: function(){},
    rumiTime: function(){},
    dayOfWeek: function(){},
}