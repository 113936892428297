// import axios from "axios"
// import config from "../../../config"
import http from '@/services/BackendService'

const state = {
    exams: []
}

const getters = {

}

const mutations = {
    retreiveExamPayment(state, histories){
        state.exams =  histories
    }
}

const actions = {
    retreiveExamPayment(context){
        return new Promise((resolve, reject) => {
            http.get(`payment/exam`)
                .then(response => {
                    resolve(response.data)
                    context.commit('retreiveExamPayment', response.data.histories)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}