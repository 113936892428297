<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
                <h1>hasil kuliah</h1>
                <p>{{$store.state.auth.token}}</p>
            </div>  
        </div>
        
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>