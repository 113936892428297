// import axios from "axios"
// import config from "../../../config"
import http from '@/services/BackendService'

const state = {
    yurans: []
}

const getters = {

}

const mutations = {
    retreiveYuranHistories(state, histories){
        state.yurans = histories
    }
}

const actions = {
    retreiveYuranHistories(context){
        return new Promise((resolve, reject) => {
            http.get(`payment/yuran`)
                .then(response => {
                    context.commit('retreiveYuranHistories', response.data.payments)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}