import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filter from './helpers/filter'
import VueProgressBar from 'vue-progressbar'
// import $ from 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'

//------------------------------------fontawesome-----------------------------------------------
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//------------------------------------Progressbar-----------------------------------------------
const options = {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, options)

//------------------------------------sweet alert2----------------------------------------------
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; // If you don't need the styles, do not connect
Vue.use(VueSweetalert2);

//------------------------------------number format--------------------------------------------
//https://www.npmjs.com/package/vue-filter-number-format
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

//------------------------------------fileter--------------------------------------------------
Vue.filter('upText', function(text){
  return text.charAt(0).toUpperCase() + text.slice(1)
});

Vue.filter('thaiDate', function(date){
  return moment(date).format('DD/MM/YYYY');
});
 
Vue.filter('numFormat', numFormat(numeral));

Vue.config.productionTip = false

new Vue({
  el: "#app",
  router,
  store,
  filter,
  render: h => h(App),
})
