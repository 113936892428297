import http from '@/services/BackendService'

const state = {
    biodata: {},
}

const getters = {

}

const mutations = {
    retreiveBiodata(state, biodata){
        state.biodata = biodata
    }
}

const actions = {
    retreiveBiodata(context){
        return new Promise((resolve, reject) => {
            http.get(`profile/biodata`)
                .then(response => {
                    resolve(response)
                    context.commit('retreiveBiodata', response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}