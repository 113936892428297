<template>
    <div class="container-fluid">
        <!-- <p>aa : {{aa}}</p> -->
        <form action="">
            <div class="form-group">
                <div class="form-row">
                    <div class="col-12 co-md-6 col-lg-6 col-xl-6">
                        <label for="">nama</label>    
                        <input type="text" 
                            class="form-control"
                            :class="{'is-invalid': form.errors.has('firstname_rumi')}"
                            v-model="form.firstname_rumi">
                        <has-error :form="form" field="firstname_rumi"></has-error>
                    </div>   
                    <div class="col-12 co-md-6 col-lg-6 col-xl-6">
                        <label for="">nasab</label> 
                        <input type="text" 
                            class="form-control"
                            v-model="form.lastname_rumi"
                            :class="{'is-invalid': form.errors.has('lastname_rumi')}">
                        <has-error :form="form" field="lastname_rumi"></has-error>    
                    </div>   
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Vue from "vue";
    import { Form, HasError, AlertError } from "vform";
    
    Vue.component(HasError.name, HasError);
    Vue.component(AlertError.name, AlertError);
    export default {
        name: 'biodata-form',
        props: {
            biodata:{
                type: Object,
                required: true
            }
        },
        data(){
            return{
                form: new Form({
                    firstname_rumi: '',
                    lastname_rumi: '',
                }),
            }
        },
        watch:{
            biodata: function(biodata){
                this.form.firstname_rumi = biodata.firstname_rumi
                this.form.lastname_rumi = biodata.lastname_rumi
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>