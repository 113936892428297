import axios from 'axios'
import config from '../../../config'

const state = {
    mailing: ''
}

const getters = {

}

const mutations = {
    verify(state, data){
        state.mailing = data
    }
}

const actions = {
    verify(context, payload){
        axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token')
        return new Promise((resolve, reject) => {
            payload.post(`${config.apiURL}/email/verify`, {
                    // headers:{
                    //     'Authorization': 'Bearer 25|ogs1AgbqTdByLirRy3zb4TT8X3PK5LK4BBcRSrtA'
                    // }
            })
                .then(response => {
                    context.commit('verify', response.dara)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }  
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}