import Vue from "vue"
import Vuex from "vuex"

// import auth from "./modules/authentication/auth"
import authentication from "./modules/authentication/authentication.js"
import email from "./modules/email/verify"
import profile from "./modules/profile/biodata"
import learning from "./modules/learning/socialLink"
import learningSchedule from "./modules/learning/schedule"
import paymentYuran from "./modules/payment/yuran"
import paymentExam from "./modules/payment/exam"

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        // auth,
        authentication,
        email,
        profile,
        learning,
        learningSchedule,
        paymentYuran,
        paymentExam,
    }
})

