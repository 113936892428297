<template>
    <div class="container-fluid">
        <div class="row mt-3 justify-content-center">
            <div class="col-1 col-12 col-md-12 col-lg-12 col-xl-12">
                <h4 class="text-center">HASIL TAMHIDI / MUSTAWA</h4>
                <div class="accordion" id="accordionExample">
                    <div class="card" v-for="(item, index) in items" :key="index">
                        <div class="card-header bg-info" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" :data-target="'#Collapse'+item.id" aria-expanded="true" :aria-controls="item.id">
                                 {{item.semester}} / {{item.year}}
                            </button>
                        </h2>
                        </div>

                        <div :id="'Collapse'+item.id" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <ul>
                                    <li v-if="applyYear == '1'">
                                        <span><b>Tamhidi {{ item.level }}</b></span>
                                        <ul>
                                            <li :class="textFilter(item.score)"><b>Markah :</b> {{item.score}}</li> 
                                        </ul>
                                    </li>
                                    <li v-if="applyYear == '2'">
                                        <span><b>Mustawa {{ item.level }}</b></span>
                                        <ul>
                                            <li><b>Natijah :</b> 
                                                <span class="text-success" v-if="item.score == 1"> Lulus</span>    
                                                <span class="text-danger" v-else-if="item.score == 2"> Tidak lulus</span>    
                                                <span class="text-warning" v-else> Belum ada natijah</span>    
                                            </li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import http from '@/services/BackendService'
    export default {
        mounted(){
            this.retreiveTamhidiResult()
        },
        data(){
            return{
                items: [],
                applyYear: '',
            }
        },
        methods:{
            retreiveTamhidiResult(){
                http.get('tamhidi/result')
                    .then(response => {
                        this.items = response.data.result
                        this.applyYear = response.data.type
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            textFilter(score){
                if(score == '' || score == null){
                    return 'text-warning'
                }else if(score < 50){
                    return 'text-danger'
                }else{
                    return 'text-success'
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>