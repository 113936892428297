<template>
    <div class="container-fluid">
        <div class="row mt-3 mb-5">
            <div class="col-1 col-12 col-md-12 col-lg-12 col-xl-12">
                <h4 class="text-center">SEJARAH BAYARAN</h4>
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header bg-info" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    YURAN
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <ul>
                                    <li v-for="(item, index) in yurans" :key="index">
                                        {{item.term}}/{{item.academic_year}} : 
                                        <button v-if="item.pay_status == 'Belum bayar'" class="btn btn-danger btn-sm"><i class="fas fa-minus-square"></i> belum bayar</button>
                                        <button v-else-if="item.pay_status == 'BELUM LUNAS'" class="btn btn-warning btn-sm text-white"><i class="fas fa-minus-square"></i> belum lunas</button>
                                        <button v-else class="btn btn-success btn-sm"><i class="far fa-check-circle"></i> sudah lunas</button>

                                        <ul>
                                            <li v-for="(yuran, index) in item.yuran" :key="index" class="mt-2">
                                                <p style="line-height: 10px;"><b>Jumlah wang : {{yuran.money | numFormat(0,0)}} ฿</b></p>
                                                <p style="line-height: 10px"><b>Tarikh catat : </b>{{yuran.pay_date | thaiDate}}</p>
                                                <p style="line-height: 10px"><b>Pegawai : </b>{{yuran.name}} {{yuran.lastname}}</p>
                                            </li>
                                        </ul>

                                    </li>
                                    <hr>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg-info" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    UJIAN
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                <ul>
                                    <li v-for="(item, index) in exams" :key="index">
                                        <p>{{item.term}}/{{item.year}} 
                                            <button v-if="item.pay_status == 'Belum bayar'" class="btn btn-danger btn-sm"><i class="fas fa-minus-square"></i> belum bayar</button>
                                            <button v-else-if="item.pay_status == 'BELUM LUNAS'" class="btn btn-warning btn-sm text-white"><i class="fas fa-minus-square"></i> belum lunas</button>
                                            <button v-else class="btn btn-success btn-sm"><i class="far fa-check-circle"></i> sudah lunas</button>
                                        </p>
                                        <ul>
                                            <li v-if="item.pay_date">
                                                <p style="line-height: 10px;"><b>Jumlah wang : {{item.money | numFormat(0,0)}} ฿</b></p>
                                                <p style="line-height: 10px"><b>Tarikh catat : </b>{{item.pay_date | thaiDate}}</p>
                                                <p style="line-height: 10px"><b>Pegawai : </b>{{item.name}} {{item.lastname}}</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg-info" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    PEMBANGUNAN AKADEMIK
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                under developing
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg-info" id="headingFour">
                            <h2 class="mb-0">
                                <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                    MUSTAWA BAHASA ARAB
                                </button>
                            </h2>
                        </div>
                        <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                under developing
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div> 

        <!-- <br><br><br><br> -->

    </div>
</template>

<script>
    import { mapState } from 'vuex'
    // import $ from "jquery";
    // import { mapState } from "vuex";

    export default {
        mounted(){
            this.retreiveYuranHistories()
            this.retrievExamPaymentHistories()
        },
        computed:{
            ...mapState('paymentYuran', [
                'yurans'
            ]),
            ...mapState('paymentExam', [
                'exams'
            ])
        },
        methods:{
            retrievExamPaymentHistories(){
                this.$store.dispatch("paymentExam/retreiveExamPayment")
                    .then(() => {
                        // console.log(response)
                    })
                    .catch(() => {
                        // console.log(error)
                    })
            },
            retreiveYuranHistories(){
                this.$Progress.start()
                this.$store.dispatch("paymentYuran/retreiveYuranHistories")
                    .then(response => {
                        this.$Progress.finish();
                        this.yuran = response.data.payments
                        // console.log(response)
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch("authentication/cencelAuthen")
                        }
                        this.$Progress.fail();
                        console.log(error)
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>