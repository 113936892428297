<template>
    <div class="container-fluid">
      <div class="row mt-3">
            <div class="col-1 col-12 col-md-2 col-lg-2 col-xl-2">
                
            </div> 
            <div class="col-10 col-12 col-md-8 col-lg-8 col-xl-8">

                <h3 class="text-center">
                    ISYTIHAR
                </h3>

                <div class="card text-white mb-3 card-backgroung">
                    <div class="card-body">
                        <h5>
                          <b>bermula perkuliahan 05/07/2021</b>
                        </h5>
                        <p><i>read more...</i></p>
                        <p><i>idarah KISDA : 27/06/2021</i></p>
                        
                    </div>
                </div>

                <div class="card text-white mb-3 card-backgroung">
                    <div class="card-body">
                        <h5>
                          <b>langkah-langkah persiapan belajar online</b>
                        </h5>
                        <p><i>read more...</i></p>
                        <p><i>idarah KISDA : 26/06/2021</i></p>    
                       
                        
                    </div>
                </div>

            </div> 
            <div class="col-1 col-12 col-md-2 col-lg-2 col-xl-2">
                
            </div> 
        </div> 
  </div>
</template>

<script>
  export default {
      
  }
</script>

<style lang="css" scoped>
    .card-backgroung{
        background-color: #646467;
    }   
</style>
