<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-center mt-3 mt-3">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">

              <div class="card mb-3 card-backgroung">
                  <div class="card-header text-white">
                      <b>JADWAL PERKULIAN HARI INI</b>
                  </div>
                  <div class="card-body card-backgroung-green">
                      <table class="table">
                          <tbody>
                              <tr v-for="(item, index) in subjects" :key="index">
                                <td valign="top" align="left" v-if="is_leader == true">
                                  <button v-if="item.student_attend == null || item.status == 1"
                                      class="btn btn-primary btn-sm btn-block"
                                      @click="openConfirmingForm(item.id)"
                                      :disabled="disabledFilter(item.student_attend, item.start_time)">
                                      KONFIRM TANDA TANGAN
                                  </button>
                                  <button v-if="item.status == 2 && item.student_attend != null" class="btn btn-success btn-sm btn-block" disabled>
                                    SUDAH KONFIRM
                                  </button>
                                  <button v-if="item.status == 2 && item.student_attend != null"
                                      class="btn btn-danger btn-sm btn-block"
                                      @click="cancelAttendance(item.student_attend, item.id)">
                                      MEMBATAL
                                  </button>
                                  <p class="mt-3"><b>Masa :</b> {{item.start_time | rumiTime}}</p>
                                  <p ><b>Madah :</b> {{item.s_rumiName | upText}}</p>
                                  <p ><b>Pensyarah :</b> {{item.t_fnameRumi | upText}} {{item.t_lnameRumi | upText}}</p>
                                  <p v-if="is_leader"><b>Telpon :</b> {{item.t_telephone}}</p>
                                </td>
                                <td valign="top" align="left" v-if="is_leader == false">
                                  <button v-if="item.student_attend == null"
                                      class="btn btn-primary btn-sm btn-block"
                                      @click="openConfirmingForm(item.id)"
                                      :disabled="disabledFilter(item.student_attend, item.start_time)">
                                      IKUTI PERKULIAHAN
                                  </button>
                                  <button v-if="item.student_attend != null" class="btn btn-success btn-sm btn-block" disabled>
                                    SUDAH MASUK KELAS
                                  </button>
                                  <button v-if="item.student_attend != null"
                                      class="btn btn-danger btn-sm btn-block"
                                      @click="cancelAttendance(item.student_attend, item.id)">
                                      MEMBATAL
                                  </button>
                                  <p class="mt-3"><b>Masa :</b> {{item.start_time | rumiTime}}</p>
                                  <p ><b>Madah :</b> {{item.s_rumiName | upText}}</p>
                                  <p ><b>Pensyarah :</b> {{item.t_fnameRumi | upText}} {{item.t_lnameRumi | upText}}</p>
                                  <p v-if="is_leader"><b>Telpon :</b> {{item.t_telephone}}</p>
                                </td>
                              </tr>
                              <tr v-for="tundaSubject in tunda" :key="tundaSubject.id">
                                <td>
                                  <p class="text-danger"><b>Tunda kepada hari/masa :- </b> <br>
                                    {{tundaSubject.teaching_day | dayOfWeek}} {{tundaSubject.teaching_date | thaiDate}} {{tundaSubject.start_time | rumiTime}}
                                  </p>
                                  <p><b>Madah : </b>{{tundaSubject.s_rumiName}}</p>
                                  <p><b>Pensyarah :</b> {{tundaSubject.t_fnameRumi | upText}} {{tundaSubject.t_lnameRumi | upText}}</p>
                                  <p v-if="is_leader"><b>Telpon :</b> {{tundaSubject.t_telephone}}</p>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              <div class="card mb-3 card-backgroung" v-if="is_leader">
                  <div class="card-header bg-warning text-white">
                      <b>UBAH KONFIRM TELAH LEPAS</b>
                  </div>
                  <div class="card-body card-backgroung-green">
                      <table class="table">
                          <tbody>
                              <tr v-for="(item, index) in forgots" :key="index">
                                <td valign="top" align="left">
                                  <button v-if="item.status == 1"
                                      class="btn btn-primary btn-sm btn-block"
                                      @click="openConfirmingForm(item.id)">
                                      KONFIRM TANDA TANGAN
                                  </button>
                                  <button v-if="item.status == 2" disabled
                                      class="btn btn-success btn-sm btn-block"
                                      @click="openConfirmingForm(item.id)">
                                      SUDAH KONFIRM
                                  </button>
                                  <button v-if="item.status == 2"
                                      class="btn btn-danger btn-sm btn-block"
                                      @click="cancelAttendance(item.student_attend, item.id)">
                                      MEMBATAL TANDA TANGAN
                                  </button>

                                  <p v-if="item.created_at == item.teaching_date" class="mt-3"><b>Tarikh :</b> {{item.created_at | thaiDate}}</p>
                                  <p v-if="item.created_at == item.teaching_date" class="mt-3"><b>Hari/Masa :</b> {{item.teaching_day | dayOfWeek}} {{item.start_time | rumiTime}}</p>
                                  
                                  <p v-if="item.created_at != item.teaching_date" class="mt-3 text-primary"><b>Tarikh :</b> {{item.created_at | thaiDate}} --> tunda kepada {{item.teaching_date | thaiDate}}</p>
                                  <p v-if="item.created_at != item.teaching_date" class="mt-3 text-primary"><b>Hari/Masa :</b> {{item.teaching_day | dayOfWeek}} {{item.start_time | rumiTime}} --> tunda kepada {{item.tunda_day | dayOfWeek}} {{item.start_time | rumiTime}}</p>

                                  <p ><b>Madah :</b> {{item.s_rumiName | upText}}</p>
                                  <p ><b>Pensyarah :</b> {{item.t_fnameRumi | upText}} {{item.t_lnameRumi | upText}}</p>
                                  <p><b>Telpon :</b> {{item.t_telephone}}</p>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              <div class="card text-white mb-3 card-backgroung">
                    <div class="card-body">
                        <h5 class="card-title">
                            GRUP LINE KELAS ANDA <img src="icon/line_icon.png" alt="" srcset="" width="25px" height="25px">
                        </h5>
                        <p>code untuk masuk grup line : <b>{{link.social_link_code}}</b></p>
                        <p>
                            link masuk grup line kelas <b> {{link.faculty}} {{link.department}} {{link.grade}}/{{link.name_room}} </b> : 
                            <a v-show="disabled" class="btn btn-primary btn-sm" :href="link.social_link_line" target="_blank">click</a>    
                        </p>
                    </div>
              </div>

              <div class="card text-white mb-3 card-backgroung">
                  <div class="card-body">
                      <h5 class="card-title">
                          GRUP LINE SUPPORT MAHASISWA <img src="icon/line_icon.png" alt="" srcset="" width="25px" height="25px">
                      </h5>
                      <p>jika ada pertanyaan tentang pengajian online sila ikuti grup line ini</p>
                      <p>
                          link masuk grup support mahasiswa : 
                          <a href="https://lin.ee/w2wVEIl" target="_blank"><img height="36" border="0" src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"></a>
                      </p>
                  </div>
              </div>
            </div>
        </div> 

        <!-- <attend-confirming showModal/> -->
        <div class="modal fade" id="attend-confirming" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="is_confirm" id="staticBackdropLabel">PASTIKAN</h5>
                    <h5 class="modal-title" v-else id="staticBackdropLabel">PASTIKAN PEMBATALAN</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="!is_confirm ? cancelConfirm() : confirm()">
                    <div class="modal-body">
                        <div class="form-group">
                            <input type="text" v-model="password" class="form-control" :class="{'is-invalid': serverError.password}" placeholder="password">
                            <div v-if="serverError.password" class="mt-1" style="color: red">
                                {{serverError.password[0]}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">batal</button> -->
                        <button type="submit" :disabled="confirmDisabled" v-if="is_confirm" class="btn btn-success">SAVE</button>
                        <button type="submit" :disabled="confirmDisabled" v-else class="btn btn-warning text-white">SAVE</button>
                    </div>
                </form>
                </div>
            </div>
        </div>

        <br><br>
        
    </div>
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";
import moment from "../../../node_modules/moment";

export default {
  data() {
    return {
      link: "",
      disabled: false,
      confirmDisabled: false,
      timer: '',
      serverError: [],
      password: "",
    }
  },
  created(){
    this.retreiveSocialLink();
    this.retreiveSchedule();
    this.timer = setInterval(this.retreiveSchedule, 30000)
  },
  computed: {
    ...mapState("learningSchedule", [
      "subjects",
      "tunda",
      "student_class_attend_id", 
      "is_confirm",
      "forgots",
      "is_leader"
    ]),
  },
  methods: {
    cancelConfirm(){
      this.$Progress.start()
      this.confirmDisabled = true;
      this.$store.dispatch('learningSchedule/cancelConfirm', {password: this.password})
        .then(() => {
          this.$store.dispatch("learningSchedule/retreiveSchedule")
          this.serverError = ''
          $("#attend-confirming").modal("hide");
          this.$Progress.finish() 
          this.confirmDisabled = false
        })
        .catch(error => {
          this.serverError = error.response.data.errors
          this.$Progress.fail()
          this.confirmDisabled = false
        })
    },
    confirm() {
      this.$Progress.start();
      this.confirmDisabled = true;
      this.$store
        .dispatch("learningSchedule/confirm", this.password)
        .then(response => {
          $("#attend-confirming").modal("hide");
          if(response.data.is_complete == true){
            this.$swal("berhasil", "pendaftaran berhasil", "success");
          }else{
            this.$swal("gagal", "pendaftaran gagal , sila cuba sekali lagi!", "warning");
          }
          
          this.$store.dispatch("learningSchedule/retreiveSchedule")

          this.serverError = ''
          this.$Progress.finish();
          this.confirmDisabled = false;
        })
        .catch((error) => {
          this.serverError = error.response.data.errors
          this.$Progress.fail();
          this.confirmDisabled = false;
        });
    },
    cancelAttendance(student_class_atten_id, teaching_history_id){
      $("#attend-confirming").modal("show");
      this.password = ''
      this.$store.dispatch("learningSchedule/cancelAttendance", {
        student_class_atten_id: student_class_atten_id, 
        teaching_history_id: teaching_history_id
        })
    },
    disabledFilter(student_attend, start_time) {
      let current_time = moment().format("HH:mm:ss");
      if(this.is_leader == true){
        if (current_time < start_time) {
          return true;
        } else if (current_time >= start_time) {
          return false;
        } else if (current_time >= start_time) {
          return true;
        } else {
          return true;
        }
      }else{
        if (current_time < start_time) {
          return true;
        } else if (current_time >= start_time && student_attend == null) {
          return false;
        } else if (current_time >= start_time && student_attend != null) {
          return true;
        } else {
          return true;
        }
      }
    },
    openConfirmingForm(id) {
      $("#attend-confirming").modal("show");
      this.$store.dispatch("learningSchedule/selectSubject", id);
    },
    cancelAutoUpdate(){
      clearInterval(this.timer)
    },
    retreiveSchedule() {
      this.$store
        .dispatch("learningSchedule/retreiveSchedule")
        .then(() => {})
        .catch(() => {});
    },
    retreiveSocialLink() {
      this.$Progress.start();
      this.$store
        .dispatch("learning/retreiveSocialLink")
        .then((response) => {
          this.link = response;
          this.disabled = true;
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  }
};
</script>

<style lang="css" scoped>
  .card-backgroung {
    background-color: #646467;
  }

  .card-backgroung-green {
    background-color: white;
  }
</style>