<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-1 col-md-3 col-lg-3"></div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-header text-center">
            LOGIN
          </div>
          <div class="card-body">
            <form @submit.prevent="submitLogin()">
              <div class="form-group">
                <label for="exampleInputEmail1">no. identitas mahasiswa</label>
                <input v-model="username" type="text" class="form-control" :class="{'is-invalid': serverError.username}" placeholder="no. Identitas mahasiswa"/>
                <div style="color: red" class="mt-1" v-if="serverError.username">{{serverError.username[0]}}</div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">password</label>
                <input v-model="password" type="password" class="form-control" :class="{'is-invalid': serverError.password}" placeholder="password"/>
                <div style="color: red" class="mt-1" v-if="serverError.password">{{serverError.password[0]}}</div>
              </div>
              <button :disabled="disabled" type="submit" class="btn btn-primary btn-lg btn-block">
                login
              </button>
            </form>
          </div>
        </div>

        <!-- <div class="card mt-3">
          <div class="card-body text-center">
              <p>jika tidak boleh login atau ada pertanyaan tentang pengajian online sila ikuti grup line ini </p>
              <p>
                  <a href="https://lin.ee/w2wVEIl" target="_blank"><img height="36" border="0" src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"></a>
              </p>
          </div>
      </div> -->

      </div>
      <div class="col-1 col-md-3 col-lg-3"></div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import { Form, HasError, AlertError } from "vform";

// Vue.component(HasError.name, HasError);
// Vue.component(AlertError.name, AlertError);
import http from "@/services/BackendService"

export default {
  data() {
    return {
      disabled: false,
      username: "",
      password: "",
      device_name: "browser",
      serverError: {},
    };
  },
  methods: {
    // login() {
    //   this.$Progress.start()
    //   this.disabled = true
    //   this.$store
    //     .dispatch("auth/login", {
    //       username: this.username,
    //       password: this.password,
    //       device_name: "browser",
    //     })
    //     .then(() => {
    //       this.disabled = false
    //       this.$Progress.finish()
    //       this.$router.push({ name: "Menu" });
    //     })
    //     .catch((error) => {
    //       this.serverError = error.response.data.errors
    //       this.disabled = false
    //       this.$Progress.fail()
    //     });
    // },
    login(){
      this.disabled = true
      this.$store.dispatch('authentication/signIn', {
        username: this.username,
        password: this.password,
      })
        .then(() => {
          this.disabled = false
          this.$Progress.finish()
          this.$router.push({ name: 'Menu' })
          this.serverError = {}
        })
        .catch(error => {
            if(error.response.status == 401 || error.response.status == 419){
              this.$store.dispatch("authentication/cencelAuthen")
            } 
          this.serverError = error.response.data.errors
          // console.log(JSON.stringify(error.response.data.errors))
          this.disabled = false
          this.$Progress.fail()
        })
    },
    submitLogin(){
      this.disabled = true
      this.$Progress.start()
      http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
        .then(() => {
          this.login()
        })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>