import http from '@/services/BackendService'
import moment from 'moment';

const state = {
    subjects: [],
    tunda: [],
    student_class_attend_id: '',
    teaching_history_id: '',
    is_confirm: false,
    is_leader: false,
    forgots: [],
}

const getters = {

}

const mutations = {
    // updateSubjectStatus(state){
    //     const index = state.subjects.findIndex(item => item.id == state.student_class_attend_id)
    //     state.subjects[index].status = true
    // },
    selectSubject(state, id){
        state.student_class_attend_id = id
        state.is_confirm = true
    },
    schedule(state, subjects){
        var newForgots = [];
        newForgots = subjects.forgots.map(item => {
            return Object.assign(item, {tunda_day: moment(item.teaching_date).weekday()})
        })
        state.subjects = subjects.subjects
        state.tunda = subjects.tunda
        state.is_leader = subjects.is_leader
        state.forgots = newForgots
    },
    cancelAttendance(state, payload){
        state.student_class_attend_id = payload.student_class_atten_id
        state.teaching_history_id = payload.teaching_history_id
        state.is_confirm = false
    }
}

const actions = {
    cancelConfirm(context, payload){
        return new Promise((resolve, reject) => {
            let data = new FormData()
            data.append("password", payload.password)
            data.append("student_class_attend_id", context.state.student_class_attend_id)
            data.append("teaching_history_id", context.state.teaching_history_id)
            http.post(`learning/scheduleCancel`, data)
                .then(response => {
                    resolve(response)
                })    
                .catch(error => {
                    reject(error)
                })
        })
    },
    cancelAttendance(context, payload){
        context.commit("cancelAttendance", payload)
    },
    confirm(context, password){
        let id = context.state.student_class_attend_id
        // console.log(id.id)
        // let form = Object.assign(formData, id)
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('smk_access_token')
        let data = new FormData()
        data.append('password', password)
        data.append('id', id)
        return new Promise((resolve, reject) => {
            // form.post(`${config.apiURL}/learning/schedule`)
            http.post('learning/schedule', data)
                .then(response => {
                    resolve(response)
                    // context.commit('updateSubjectStatus')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    selectSubject(context, id){
        context.commit('selectSubject', id)
    },
    retreiveSchedule(context){
        return new Promise((resolve, reject) => {
            // axios.get(`${config.apiURL}/learning/schedule`, {
            //     headers:{
            //         'Authorization': 'Bearer '+localStorage.getItem('smk_access_token')
            //     }
            // })
            http.get('learning/schedule')
                .then(response => {
                    resolve(response)
                    context.commit('schedule', response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions 
}

//21316140 --> 1949800161104
//21315859 --> 1940900291389

//sakinah 1949800161104
//muhammad  1940200148494
//rusdee 1940900291389