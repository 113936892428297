<template>
    <div class="container-fluid mt-3">
        <div class="d-flex justify-content-center mt-3" style="margin-bottom: 60px !important">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <!-- biodata -->
                <ul class="list-group  list-group-flush text-center">
                    <li class="list-group-item">
                        <img :src="photo" width="140px" class="img-fluid img-thumbnail" alt="..." srcset="">
                    </li>
                    <li class="list-group-item">
                        <!-- <router-link to="/profileEdit"> -->
                            <button @click="openAlert()" class="btn btn-secondary btn-sm"><i class="fa fa-edit"></i> UBAH PROFIL</button>
                        <!-- </router-link> -->
                    </li>
                    <li class="list-group-item"><b>{{biodata.student_id}}</b></li>
                    <li class="list-group-item">{{biodata.firstname_rumi}} {{biodata.lastname_rumi}}</li>
                    <li class="list-group-item font-amiri" dir="rtl">{{biodata.firstname_jawi}} {{biodata.familyname_jawi}} {{biodata.lastname_jawi}}</li>
                    <li class="list-group-item">{{biodata.cityzen_id}}</li>
                    <li class="list-group-item">{{faculty}} {{department}}</li>
                    <li class="list-group-item">
                        <b>STATUS PERSYARATAN</b>
                        <div class="mt-3" >
                            <button class="btn btn-sm btn-block" :class="[biodata.certificate == '1' ? 'btn-success' : 'btn-warning']">
                                <span class="float-left">
                                    <!-- <i :class="{'fa fa-check': biodata.certificate == '1', 'fa fa-ban': biodata.certificate == null}" aria-hidden="true"></i> -->
                                    <!-- <i :class="[biodata.certificate == '1' ? 'fa fa-ban' : 'fa fa-check']" aria-hidden="true"></i> -->
                                </span>
                                <span>
                                    SYAHADAH 3 TSANAWI
                                </span>
                            </button>
                        </div>
                        <div class="mt-3" >
                            <button class="btn btn-sm btn-block" :class="[biodata.citizen_book == '1' ? 'btn-success' : 'btn-warning']">
                                <span class="float-left">
                                    <!-- <i :class="[biodata.citizen_book == '1' ? 'fa fa-check' : 'fa fa-ban']" aria-hidden="true"></i> -->
                                </span>
                                <span>
                                    SENARAI DAPUR
                                </span>
                            </button>
                        </div>
                        <div class="mt-3" >
                            <button class="btn btn-sm btn-block" :class="[biodata.id_book == '1' ? 'btn-success' : 'btn-warning']">
                                <span class="float-left">
                                    <!-- <i :class="[biodata.id_book == '1' ? 'fa fa-check' : 'fa fa-ban']" aria-hidden="true"></i> -->
                                </span>
                                <span>
                                    KAD PENGENALAN
                                </span>
                            </button>
                        </div>
                        <div class="mt-3" >
                            <button @click="evedanceUploadForm()" class="btn btn-primary btn-sm btn-block">
                                <span class="float-left">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                </span>
                                <span>
                                    UPLOAD PERSYARATAN
                                </span>
                            </button>
                        </div>
                        <!-- <div class="mt-3" >
                            <button class="btn btn-success btn-sm btn-block">
                                <span class="float-left">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                                </span>
                                <span>
                                    SYAHADAH 3 TSANAWI
                                </span>
                            </button>
                        </div>
                        <div class="mt-3" >
                            <button class="btn btn-success btn-sm btn-block">
                                <span class="float-left">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                                </span>
                                <span>
                                    SENARAI DAPUR
                                </span>
                            </button>
                        </div>
                        <div class="mt-3" >
                            <button class="btn btn-success btn-sm btn-block">
                                <span class="float-left">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                                </span>
                                <span>
                                    KAD PENGENALAN
                                </span>
                            </button>
                        </div> -->
                    </li>

                </ul>
                <!-- /.biodata -->

                <div class="modal fade" id="warning-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header bg-warning">
                                <h5 class="modal-title" id="staticBackdropLabel">Perhatian</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body text-center">
                                Fitur ini akan buka pada 01-06-2022
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="evedance-form-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header bg-primary">
                                <h5 class="modal-title text-white" id="staticBackdropLabel">Upload persyaratan</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="uploadEvedance()" enctype="multipart/form-data">
                                    <div class="form-group">
                                        <label class="col-form-label">Salinan syahadah</label>
                                        <input type="file" class="form-control"
                                            :class="{'is-invalid': serverError.syahadah}"
                                            @change="changeSyahadah">
                                        <div v-if="serverError.syahadah" class="text-danger text-sm-left">
                                            {{serverError.syahadah[0]}}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Salinan kasyfu nilai</label>
                                        <input type="file" class="form-control"
                                            :class="{'is-invalid': serverError.kasyfunilai}"
                                            @change="changeKasyfuNilai">
                                        <div v-if="serverError.kasyfunilai" class="text-danger text-sm-left">
                                            {{serverError.kasyfunilai[0]}}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Salinan senarai dapur</label>
                                        <input type="file" class="form-control"
                                            :class="{'is-invalid': serverError.senaraidapur}"
                                            @change="changeSenaraiDapur">
                                        <div v-if="serverError.senaraidapur" class="text-danger text-sm-left">
                                            {{serverError.senaraidapur[0]}}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Salinan kadpengenalan</label>
                                        <input type="file" class="form-control"
                                            :class="{'is-invalid': serverError.kadpengenalan}"
                                            @change="changeKadPengenalan">
                                        <div v-if="serverError.kadpengenalan" class="text-danger text-sm-left">
                                            {{serverError.kadpengenalan[0]}}
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <img v-if="syahadah_url" :src="syahadah_url" width="100%" height="100%">
                                    </div> -->
                                    <button :disabled="disabled" type="submit" class="btn btn-success">upload</button>
                                    <span v-if="disabled"> Sedang upload ! tunggu sekejap</span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div> 
    </div>
</template>

<script>
    // import BiodataForm from './BiodataForm.vue'
    import $ from "jquery";
    import http from '@/services/BackendService'
    export default {
        components:{
            // 'biodata-form': BiodataForm
        },
        mounted(){
            this.retreiveBiodata()
        },
        data(){
            return{
                biodata: {
                    student_id: 'xxxxxxxx',
                    firstname_rumi: '',
                    lastname_rumi: '',
                    firstname_jawi: '',
                    lastname_jawi: '',
                    familyname_jawi: '',
                    email: '',
                    cityzen_id: '',

                    citizen_book: '',
                    id_book: '',
                    transcript: '',

                },
                photo: 'male.png',
                faculty: '',
                department: '',
                //file upload
                syahadah: '',
                syahadah_url: '',
                kasyfunilai: '',
                senaraidapur: '',
                kadpengenalan: '',
                //server errors
                serverError: {},
                disabled: false,
            }
        },
        methods:{
            uploadEvedance(){
                this.disabled = true
                this.$Progress.start()
                let data = new FormData()
                data.append('syahadah', this.syahadah)
                data.append('kasyfunilai', this.kasyfunilai)
                data.append('senaraidapur', this.senaraidapur)
                data.append('kadpengenalan', this.kadpengenalan)
                http.post(`profile/evedanceUpload`, data)
                    .then(() => {
                        this.disabled = false
                        this.$Progress.finish()
                        $("#evedance-form-modal").modal("hide")
                        // console.log(response)
                        this.syahadah = ''
                        this.kasyfunilai = ''
                        this.senaraidapur = ''
                        this.kadpengenalan = ''
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.serverError = error.response.data.errors
                        this.disabled = false
                    })

            },
            changeSyahadah(e){
                // const syahadah = e.target.files[0]
                this.syahadah = e.target.files[0]
                // this.syahadah_url = URL.createObjectURL(syahadah)
            },
            changeKasyfuNilai(e){
                // const photo = e.target.files[0]
                this.kasyfunilai = e.target.files[0]
                // this.syahadah_url = URL.createObjectURL(syahadah)
            },
            changeSenaraiDapur(e){
                // const photo = e.target.files[0]
                this.senaraidapur = e.target.files[0]
                // this.syahadah_url = URL.createObjectURL(syahadah)
            },
            changeKadPengenalan(e){
                // const photo = e.target.files[0]
                this.kadpengenalan = e.target.files[0]
                // this.syahadah_url = URL.createObjectURL(syahadah)
            },
            evedanceUploadForm(){
                $("#evedance-form-modal").modal("show")
            },
            openAlert(){
                $("#warning-modal").modal("show")
            },
            retreiveBiodata(){
                this.$Progress.start()
                this.$store.dispatch('profile/retreiveBiodata')
                    .then(response => {
                        this.biodata = response.data.biodata
                        this.photo = "https://kisda.online/images/"+response.data.photo.images
                        this.faculty = response.data.faculty
                        this.department = response.data.department
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch("authentication/cencelAuthen")
                        }
                        this.$Progress.fail()
                    })
            }
        }   
    }
</script>

<style lang="scss" scoped>

</style>